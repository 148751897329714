import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css';
import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [uid, setUid] = useState(null);
  const [background, setBackground] = useState('1');
  const [makeup, setMakeup] = useState('0');
  const [blendingMode, setBlendingMode] = useState('');
  const [filter, setFilter] = useState('false');
  const [enhance, setEnhance] = useState('true');
  const [alpha, setAlpha] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [finalImages, setFinalImages] = useState([]);
  const [error, setError] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [debugMessage, setDebugMessage] = useState(null);

  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);

  const API_URL = process.env.REACT_APP_API_URL || 'https://api-us.ddmnaturamaquillaje.com';
  const DATA_URL = process.env.REACT_APP_API_URL || 'https://admin.ddmnaturamaquillaje.com';
  const API_ERROR_MESSAGE = 'No podemos procesar tu solicitud en este momento. Inténtalo de nuevo más tarde.';

  /*
  useEffect(() => {
    let timer;
    if (isLoading) {
      setDebugMessage(null);
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setDebugMessage(elapsedTime === 0 ? null : `Tiempo de procesamiento y transferencia de datos: ${elapsedTime}s`);
      setElapsedTime(0);
    }
    return () => clearInterval(timer);
  }, [isLoading]);
  */

  useEffect(() => {
    // Check for camera availability
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const hasCamera = devices.some(device => device.kind === 'videoinput');
      setCameraAvailable(hasCamera);
    });
  }, []);

  useEffect(() => {
    if (error) {
      // scroll to error message
      const errorElement = document.getElementById('error-message');
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [error]);

  /*
  useEffect(() => {
    if (finalImage) {
      // scroll to result
      const resultElement = document.getElementById('result');
      if (resultElement) {
        resultElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [finalImage]);
  */
  

  const resizeImage = (src, flip_horizontal) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Target dimensions
        const targetWidth = 600;
        const targetHeight = 800;

        // Calculate aspect ratios
        const imgAspectRatio = img.width / img.height;
        const targetAspectRatio = targetWidth / targetHeight;

        let drawWidth, drawHeight, offsetX, offsetY;

        if (imgAspectRatio > targetAspectRatio) {
          // Image is wider relative to its height
          drawWidth = img.width * (targetHeight / img.height);
          drawHeight = targetHeight;
          offsetX = (drawWidth - targetWidth) / 2;
          offsetY = 0;
        } else {
          // Image is taller relative to its width
          drawWidth = targetWidth;
          drawHeight = img.height * (targetWidth / img.width);
          offsetX = 0;
          offsetY = (drawHeight - targetHeight) / 2;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        if (flip_horizontal) {
          // flip the image horizontally
          ctx.translate(targetWidth, 0);
          ctx.scale(-1, 1);
        }

        // Draw the image centered on the canvas, covering the entire canvas
        ctx.drawImage(
          img,
          -offsetX, -offsetY, drawWidth, drawHeight
        );

        const resizedImageSrc = canvas.toDataURL('image/jpeg');
        resolve(resizedImageSrc);
      };
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const isCapture = e.target.name === 'capture_image';
      const reader = new FileReader();
      reader.onload = async (event) => {
        const resizedImage = await resizeImage(event.target.result, isCapture);
        setImageSrc(resizedImage);
        setDebugMessage(null);
        setFinalImages([]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    // ping api to check if server is up
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_URL}/ping/`,
      );

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(API_ERROR_MESSAGE);
      } else {
        setFinalImages([]);
        const makeupNumbers = makeup > 0 ? [makeup] : [1, 2, 3, 4, 5 ,6];
        makeupNumbers.forEach(makeupNumber => {
          submitImage(makeupNumber);          
        });
      }
    } catch (error) {
      console.error('API did not respond to ping', error);
      //setError(error.message || API_ERROR_MESSAGE);
      setError(API_ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  const submitImage = async (makeupNumber) => {
    setIsLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append('file', dataURLtoFile(imageSrc));

    try {
      const uuid = uuidv4() + '-' + new Date().getTime();
      setUid(uuid);
      const alphaParam = alpha ? `&default_alpha=${alpha}` : '';
      const blendingModeParam = blendingMode ? `&default_blending_mode=${blendingMode}` : '';
      const response = await fetch(
        `${API_URL}/process/?makeup=${makeupNumber}&background=${background}${alphaParam}${blendingModeParam}&filter=${filter}&uid=${uuid}&enhance=${enhance}`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        const json = await response.json();
        const error = json.detail || json.error || response.error || API_ERROR_MESSAGE;
        throw new Error(error);
      }

      //const result = await response.blob();
      //const imageObjectURL = URL.createObjectURL(result);
      //setFinalImage(imageObjectURL);
      
      const result = await response.json();
      if (!result.url) {
        const error = response.error || API_ERROR_MESSAGE;
        throw new Error(error);
      }

      const resultUrl = (result.url && result.url.indexOf('?') > 0 ? result.url : result.url + '?') + 'v=' + new Date().getTime();
      setFinalImages((finalImages) => [...finalImages, resultUrl]);

      //setImageSrc(null);
    } catch (error) {
      console.error('API response error', error);
      setError( (error.message && error.message !== 'Failed to fetch') ? error.message : API_ERROR_MESSAGE);
      //setFinalImage(null);
      //setImageSrc(null);
    } finally {
      setIsLoading(false);
    }
  };

  const dataURLtoFile = (dataurl) => {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const pictureStyle = { width: '25%', maxWidth: '400px', height: 'auto', overflow: 'hidden', margin: '10px' };
  const inputStyle = { marginBottom: '20px', marginRight: '20px', display: 'inline-block' };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div style={inputStyle}>
        <select className='form-control' value={background} onChange={(e) => setBackground(e.target.value)}>
          <option value="1">Fondo 1</option>
          <option value="2">Fondo 2</option>
          <option value="3">Fondo 3</option>
          <option value="4">Fondo 4</option>
          <option value="5">Fondo 5</option>
          <option value="6">Fondo 6</option>
        </select>
      </div>

      <div style={inputStyle}>
        <select className='form-control' value={makeup} onChange={(e) => setMakeup(e.target.value)}>
          <option value="0">Todos los maquillajes</option>
          <option value="1">Maquillaje 1</option>
          <option value="2">Maquillaje 2</option>
          <option value="3">Maquillaje 3</option>
          <option value="4">Maquillaje 4</option>
          <option value="5">Maquillaje 5</option>
          <option value="6">Maquillaje 6</option>
        </select>
      </div>

      <div style={inputStyle}>
        <select className='form-control' value={blendingMode} onChange={(e) => setBlendingMode(e.target.value)}>
          <option value="">Default</option>
          <option value="normal">Blend Normal</option>
          <option value="overlay">Blend Overlay</option>
          <option value="multiply">Blend Multiply</option>
          <option value="screen">Blend Screen</option>
        </select>
      </div>

      <div style={inputStyle}>
        <select className='form-control' value={alpha} onChange={(e) => setAlpha(e.target.value)}>
          <option value="">Default</option>
          <option value="1.00">Alpha 1.00</option>
          <option value="0.90">Alpha 0.90</option>
          <option value="0.85">Alpha 0.85</option>
          <option value="0.80">Alpha 0.80</option>
          <option value="0.75">Alpha 0.75</option>
          <option value="0.70">Alpha 0.70</option>
          <option value="0.60">Alpha 0.60</option>
          <option value="0.50">Alpha 0.50</option>
          <option value="0.40">Alpha 0.40</option>
          <option value="0.30">Alpha 0.30</option>
          <option value="0.20">Alpha 0.20</option>
          <option value="0.10">Alpha 0.10</option>
          <option value="0.01">Alpha 0.01</option>
        </select>
      </div>

      <div style={inputStyle}>
        <select className='form-control' value={enhance} onChange={(e) => setEnhance(e.target.value)}>
          <option value="false">Sin enhance</option>
          <option value="true">Con enhance</option>
        </select>
      </div>

      <div style={inputStyle}>
        <select className='form-control' value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="false">Sin filtro</option>
          <option value="true">Bilateral filter</option>
        </select>
      </div>

      {!error && (
        <div id='result'>
          { finalImages.map((image, index) => (
            <div className="image-wrapper">
              <img src={image} alt="Resultado final" />
            </div>
          ))}
        </div>
      )}

      {error && (
        <p style={{ color: 'red' }} id='error-message'>{error}</p>
      )}

      {debugMessage && (
        <p style={{ color: 'blue' }} id='debug-message'>{debugMessage}</p>
      )}

      <div style={{ marginBottom: '20px' }}>
        {imageSrc ? (
          <div>
            <Button variant='primary' className='m-2 w-100' onClick={handleSubmit} disabled={!imageSrc}>
              {isLoading ? /*`Procesando... (${elapsedTime}s)`*/`Procesando` : 'Enviar'}
            </Button>
            <div className="image-wrapper">
              <img
                src={imageSrc}
                alt="Imagen capturada"
                className='imagen'
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>
        ) : <></>
        }
          <>
            {cameraAvailable && (
              <>
                <Button variant='primary' className='m-2' onClick={() => cameraInputRef.current.click()}>
                  Tomar una foto
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  capture="user"
                  name="capture_image"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  ref={cameraInputRef}
                />
              </>
            )}
            <Button variant='primary' className='m-2' onClick={() => fileInputRef.current.click()}>
              Elegir una foto de mi galería
            </Button>
            <input
              type="file"
              accept="image/*"
              name="gallery_image"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
          </>

      </div>
      { /*
      <div>
        <Button variant='secondary' onClick={submitUserData}>
          Enviar datos al admin (prueba)
        </Button>
      </div>
      */ }
    </div>
  );
}

export default App;
